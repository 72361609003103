<template>
  <!-- 主要添加卡牌的编辑器 -->
  <div class="fs-16">
    <div class="my-toolbar-box">
      <editor-toolbar :tools="toolsMap[this.currentEditorIndex]" class="mg-l-10" @toolClick="onToolClick"
                      :showToggleIcon="true" :style="{ paddingTop: $store.state.isFullScreen ? '15px' : '0' }">
      </editor-toolbar>
    </div>
    <div v-for="v, k in editors" class="pd-b-10" v-show="tpl[k] && tpl[k].display !== 'none'" :key="k"
         :style="{ width: showCustomEditorWidth ? '672px' : '' }">
      <div class="display-flex" style="flex-wrap: wrap;">
        <div v-if="tpl[k]" :class="model.hideTitle ? 'display-none' : ''" class="fs-16 mg-l-16 lh-36 h-36 text-strong"
             style="white-space: nowrap;">{{ tpl[k].name }}
        </div>
      </div>
      <div class="pd-t-5 mg-l-10 mg-r-10" :class="k===0 && duplicateNotice?'duplicate-notice':''">
        <div :id="'edit-' + k"></div>
      </div>
      <div v-if="k < tpl.length - 1" style="border-bottom: 1px solid #f1f1f1;margin: 0 10px"></div>
    </div>
    <card-link ref="cardLink" @onSelectedCard="onSelectLinkCard"></card-link>
    <image-cover-editor v-if="doImageCoverSrc"
                        :doImageCoverSrc="doImageCoverSrc"
                        :doImageCoverSvg="doImageCoverSvg"
                        @cancelImageEdit="cancelImageEdit"
                        @finishImageCover="finishImageCover">
    </image-cover-editor>
  </div>
</template>

<script>
import request from '../../libs/api.request'
import EditorToolbar from "./EditorToolbar";
import ImageCoverEditor from "./ImageCoverEditor";
import EditorToolbarUtil from "../../libs/EditToolbarUtil";
import CardLink from "./CardLink";
import {mapGetters} from 'vuex';

export default {
  components: {CardLink, EditorToolbar, ImageCoverEditor},
  props: {
    showCustomEditorWidth: {
      type: Boolean,
      default: false
    },
    model: {
      type: Object,
      default: () => {
      }
    },
  },
  data() {
    return {
      editors: [],
      currentEditorIndex: '',
      tpl: [],
      toolsMap: [],
      linkCardEditorInfo: {
        index: 0,
        selection: null
      },
      queryWordTimeout: null,
      doImageCoverElement: null,
      doImageCoverEditor: null,
      doImageCoverSrc: '',
      duplicateNotice: false,
      queryDuplicatesTimer: null
    }
  },
  methods: {
    queryDuplicates(time) {
      if (this.queryDuplicatesTimer) {
        clearTimeout(this.queryDuplicatesTimer);
      }

      this.queryDuplicatesTimer = setTimeout(() => {
        request.request({
          url: 'card/queryDuplicates',
          params: {value: this.tpl[0].value}
        }).then((data) => {
          if (data.find) {
            this.duplicateNotice = true;
          }
        })
      }, time || 2000)
    },
    onToolClick(e) {
      console.log(e);
      if (e.tool.name === 'image') {
        if (!this.isVip && this.vipLimitData && this.vipLimitData.cardAddImg.limit <= this.vipLimitData.cardAddImg.use) {
          this.$store.commit('changeShowIntroduceVip', true)
          return
        }
      }
      console.log('???');
      EditorToolbarUtil.handlerEvent(e, this.toolsMap, this.editors, this.currentEditorIndex);
    },
    onAkCoverClick(e) {
      if (e.target.style.color == 'rgb(0, 0, 0)') {
        e.target.style.color = '#FFE5B4';
      } else {
        e.target.style.color = '#000000';
      }
    },
    checkAkCoverListener() {
      setTimeout(() => {
        let test = document.getElementsByClassName('ak-cover');
        for (let i = 0; i < test.length; i++) {
          test[i].removeEventListener('click', this.onAkCoverClick)
          test[i].addEventListener('click', this.onAkCoverClick)
        }
      }, 300);
    },
    getTagItems(queryText) {
      console.log("queryText = " + queryText);
      return new Promise((resolve, reject) => {
        request.request({
          url: '/card/queryTags',
          params: {query: queryText}
        }).then(data => {
          resolve(data.list)
        }).catch(e => {
          reject(e)
        })
      });
    },
    onSelectLinkCard(data) {
      console.log("onSelectLinkCard", data);
      this.editors[this.linkCardEditorInfo.index].model.change(writer => {
        let html = "<span class='link-card' data-lcid='" + data.card.cardId + "'>@" + data.card.keyword.trim() + "</span> ";
        let viewFragment = this.editors[this.linkCardEditorInfo.index].data.processor.toView(html);
        let modelFragment = this.editors[this.linkCardEditorInfo.index].data.toModel(viewFragment);
        this.editors[this.linkCardEditorInfo.index].execute('delete', {selection: this.linkCardEditorInfo.selection});
        setTimeout(() => {
          this.editors[this.linkCardEditorInfo.index].model.insertContent(modelFragment, this.linkCardEditorInfo.selection);
          this.editors[this.linkCardEditorInfo.index].model.insertContent(writer.createText(" "));
          this.editors[this.linkCardEditorInfo.index].editing.view.focus();
        }, 100);
      });
    },
    initEditor() {
      if (this.tpl.length > 0) {
        this.tpl.forEach((v, k) => {
          if (v.value) {//这里是修复之前编辑器把头尾去掉的bug
            let end = v.value.indexOf('</p>');
            if (end && v.value.indexOf('<p>') > end) {
              v.value = "<p>" + v.value;
            }

            let lastStar = v.value.lastIndexOf("<p>");
            if (lastStar && v.value.indexOf('</p>') < lastStar) {
              v.value += "</p>";
            }
            if (v.value.indexOf('≯#') > -1 && v.value.indexOf('#≮') > -1) {
              setTimeout(() => {
                this.$message.error('【' + v.name + '】已被加密，可能无法编辑');
              }, k * 400);
            }
          }

          let placeholder = "在此处输入内容";
          if (v.placeholder) {
            placeholder = v.placeholder;
          }

          if (this.editors[k]) {
            setTimeout(() => {
              document.getElementsByClassName('ck-placeholder')[k].setAttribute('data-placeholder', placeholder);
            }, 100);
          } else {
            AnkiEditor.create(document.querySelector('#edit-' + k), {
              placeholder: placeholder,
              simpleUpload: {
                uploadUrl: request.baseUrl + '/common/editorUploadImg',
                headers: {Authorization: this.$cookies.get('authToken')}
              },
              mention: {
                feeds: [
                  {
                    marker: '#',
                    feed: this.getTagItems
                  }
                ]
              }
            }).then(editor => {
              if (k === 0) {
                setTimeout(() => {
                  editor.editing.view.focus()
                }, 500)
              }

              Object.keys(this.toolsMap[k]).forEach(name => {
                const command = editor.commands.get(name);
                if (command) {
                  command.on('change:value', () => {
                    console.log(name + " change:value = " + command.value);
                    this.toolsMap[k][name].active = command.value;
                  });
                }
              });

              editor.matchedLinkCard = () => {
                this.linkCardEditorInfo.index = this.currentEditorIndex;
                this.linkCardEditorInfo.selection = this.editors[this.currentEditorIndex].model.document.selection;
                this.$refs.cardLink.open();
              }

              editor.imageCover = (selectedElement) => {
                this.doImageCoverElement = selectedElement;
                this.doImageCoverEditor = editor;
                let imageElement = null;
                let hasCover = false;
                if (selectedElement.name === 'imageCover') {
                  hasCover = true;
                  imageElement = selectedElement.parent;
                } else {
                  imageElement = selectedElement;
                }

                this.showImageCoverEditor(imageElement.getAttribute('src'), hasCover ? selectedElement.getAttribute('draw') : '');
              };


              editor.model.document.on('change', () => {
                let newHtml = editor.getData();
                if (newHtml.length > 6) {
                  if (newHtml.substr(0, 3).toLowerCase() === '<p>'
                    && newHtml.substr(newHtml.length - 4).toLowerCase() === '</p>'
                    && newHtml.indexOf('</p>') === newHtml.length - 4) {
                    newHtml = newHtml.substr(3, newHtml.length - 7)
                  }
                }
                if (newHtml !== this.tpl[k].value) {
                  if (k === 0) {
                    this.duplicateNotice = false;
                    this.queryDuplicates(2500);
                  }
                  this.tpl[k].value = newHtml;
                  this.$emit('editorChange', this.tpl)
                  setTimeout(() => {
                    let test = document.getElementsByClassName('ak-cover');
                    for (let i = 0; i < test.length; i++) {
                      test[i].addEventListener('click', this.onAkCoverClick)
                    }
                  }, 300);
                  if (this.model.modelId == 5 && k === 0) {
                    this.startQueryWord(newHtml);
                  }
                  this.checkAkCoverListener();
                }

                if (newHtml === '') {
                  setTimeout(() => {
                    document.getElementsByClassName('ck-placeholder')[k].setAttribute('data-placeholder', this.tpl[k].placeholder || '在此处输入内容');
                  }, 80);
                }

              });
              editor.editing.view.document.on('focus', () => {
                if (this.currentEditorIndex === 0 && k !== 0 && this.tpl[0].value) {
                  this.queryDuplicates(10);
                }
                this.currentEditorIndex = k;
                this.$emit('showSaveBtn')
              });
              editor.editing.view.document.on('blur', () => {
                // this.currentEditorIndex = '';
                // this.$emit('hideSaveBtn')
              });
              editor.setData(v.value);
              this.editors[k] = editor;
            }).catch(error => {
              console.error(error);
            });
          }
        })

        this.checkAkCoverListener();
      }
    },
    startQueryWord(word, timeout) {
      console.log("startQueryWord");
      let newWord = word.replace(/<\/?[^>]*>/g, "");
      console.log(word);
      console.log(newWord);
      if (newWord !== word) {
        this.editors[0].setData(newWord);
        return;
      }
      if (this.model.modelId != 5 || word.length > 200) {
        return;
      }

      if (this.queryWordTimeout != null) {
        clearTimeout(this.queryWordTimeout);
      }

      this.editors[1].setData("释义查询中...");
      this.queryWordTimeout = setTimeout(() => {
        this.queryWordTimeout = null;
        request.request({
          url: 'card/queryWord',
          params: {word: word}
        }).then((data) => {
          this.editors[1].setData(data.explain);
          this.editors[3].setData(data.pronun);
          this.editors[4].setData(data.example);
        })
      }, timeout || 1000)
    },
    showImageCoverEditor(src, svg) {
      this.doImageCoverSrc = src;
      this.doImageCoverSvg = svg;
    },
    cancelImageEdit() {
      this.doImageCoverSrc = '';
    },
    finishImageCover(svg) {
      this.doImageCoverEditor.model.change(writer => {
        let imageElement;
        if (this.doImageCoverElement.name === 'imageCover') {
          imageElement = this.doImageCoverElement.parent;
          writer.remove(this.doImageCoverElement);
          if (svg === '') {
            return;
          }
        } else {
          imageElement = this.doImageCoverElement;
        }

        const newImageCoverElement = writer.createElement('imageCover');
        writer.setAttribute('draw', svg, newImageCoverElement);
        writer.append(newImageCoverElement, imageElement);
      });
      this.doImageCoverSrc = '';
    },
  },
  computed: {
    ...mapGetters(['vipLimitData', 'isVip'])
  },
  watch: {
    model: function (newModel) {
      this.tpl = []
      newModel.flds.forEach((v, k) => {
        this.tpl.push({
          name: v.name,
          value: this.editors[k] ? this.editors[k].getData() : '',
          placeholder: v.placeholder,
          display: v.display
        });
      })
      setTimeout(() => {
        this.initEditor();
      }, 150)
    },
  },
  created() {
    for (let i = 0; i < 50; i++) {
      this.editors.push(null);
      this.toolsMap.push(EditorToolbarUtil.makeToolObj())
    }
  },
  mounted() {
    this.model.flds.forEach((v, k) => {
      this.tpl.push({name: v.name, value: '', placeholder: v.placeholder, display: v.display});
    });
    this.initEditor()
  }
}
</script>

<style lang="scss" scoped>
.my-toolbar-box {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 8;
  background-color: #f7f7f7;
}

::v-deep .ck-editor__editable_inline {
  min-height: 60px;
}

::v-deep .ck .ck-button .ck-disabled .ck-off {
  display: none;
}

::v-deep .ck .ck-button .ck-disabled .ck-off {
  display: none;
}

.image .ck-svg-widget {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.image .ck-svg-widget svg {
  width: 100%;
  height: 100%;
  opacity: .9;
}

.duplicate-notice {
  background-color: rgba(255, 177, 176, 0.2);
  border-radius: 10px;
}
</style>

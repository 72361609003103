<template>
  <!-- -->
  <!-- accordion  -->
  <!-- :default-expanded-keys="expansionKeys" -->
  <!-- :expand-on-click-node="false"   -->
  <!-- <draggable :list="chapterList" :group="group" forceFallback="true" style="width:100%;" animation="1000"
    filter=".forbid" fallbackTolerance="100"> -->
  <el-tree @node-click="handleNodeClick" ref="tree" :allow-drop="allowDrop" :allow-drag="allowDrag"
           :expand-on-click-node="false" :default-expanded-keys="expansionKeys" :default-checked-keys="expansionKeys"
           :data="chapterList" node-key="chapterId" :props="defaultProps" @node-drag-start="handleDragStart"
           @node-drop="handleDrop" draggable icon-class="el-icon-arrow-right" :highlight-current="true"
           @setCurrentKey="">
    <!-- @contextmenu.prevent="handleMouseRightClick($event, data)" -->
    <div class="custom-tree-node " slot-scope="{ node, data }" @click="nodeClick(data)"
         @mouseenter="handleMouseEnter(data)" @mouseleave="handleMouseLeave(data)">
      <div class="node-left">
        <svg-icon icon-class="allCard" class="w-16 h-16 mg-r-10" v-if="data.chapterId == 1001"></svg-icon>
        <svg-icon :iconClass="data.chapterId === 100 ? 'quick_box' : (data.hasPublish ?'published' :'folder')"
                  class="folder"
                  v-if="node.level === 1 && data.chapterId != 1001">
        </svg-icon>
        <div class="node-name" :style="{ opacity: data.status === 2 ? '.5' : '1' }">{{ data.name }}</div>
      </div>
      <div class="node-right">
        <el-dropdown :show-timeout="0" class="myicon" placement="bottom-start" v-if="data.chapterId != 1001">
          <svg-icon iconClass="dot_more_menu" class="w-16 h-16  mg-r-8" v-show="data.isShowMenu"></svg-icon>
          <el-dropdown-menu slot="dropdown" style="border-radius: 8px;">
            <el-dropdown-item @click.native.stop="handleMenuClick(data, menu, node)"
                              v-for="(menu, index) in dropdownMenuList" :key="menu.id">
              <svg-icon :iconClass="menu.icon" class="w-16 h-16"></svg-icon>
              <div class="fs-14 mg-l-14" v-if="menu.id === 3">{{ data.status === 1 ? '停止学习' : '恢复学习' }}</div>
              <div class="fs-14 mg-l-14" v-else>{{ menu.menuName }}</div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="node-num" v-if="data.newCardNum[0] + data.revCardNum[0] !== 0 && data.chapterId !== 1001">{{
            data.newCardNum[0] +
            data.revCardNum[0]
          }}
        </div>
        <div class="node-num1" v-if="data.newCardNum[0] + data.revCardNum[0] === 0 && data.chapterId !== 1001">{{
            data.cardNum
          }}
        </div>
        <div class="node-num1" v-if="data.chapterId === 1001" :style="{ color: showNumColor() }">{{
            todayTotalCardNum()
          }}
        </div>
      </div>
    </div>
  </el-tree>
  <!-- </draggable> -->
</template>
<script>
import request from '../../libs/api.request'
import {showMessage, handleChapterOperation} from '../../libs/message';
import draggable from 'vuedraggable';

export default {
  props: {
    chapterList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      group: {
        name: 'chapter',
        pull: false,
        put: true
      },
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      dropdownMenuList: [
        {menuName: '创建卡牌盒', icon: 'add_folder', id: 1},
        {menuName: '重命名', icon: 'edit', id: 2},
        {menuName: '停止学习', icon: 'stop_study', id: 3},
        {menuName: '导出卡牌盒', icon: 'export_chapter', id: 4},
        {menuName: '拷贝卡牌盒', icon: 'copy_chapter', id: 5},
        {menuName: '删除卡牌盒', icon: 'delete_box', id: 6},
      ],
    }
  },
  methods: {
    handleNodeClick(data, node) {
      console.log(data);
      this.$refs.tree.setCurrentKey(data.chapterId)
    },
    allowDrop(draggingNode, dropNode, type) {
      if (dropNode.data.chapterId === 1001 || dropNode.data.chapterId === 999) {
        // 不允许放置在全部卡片里面
        return false
      } else {
        return true
      }
    },
    // 不允许拖拽全部卡片
    allowDrag(draggingNode) {
      return draggingNode.data.chapterId !== 1001
    },
    handleMouseEnter(data) {
      if (data.chapterId === 999) return
      data.isShowMenu = true
    },
    handleMouseLeave(data) {
      if (data.chapterId === 999) return
      data.isShowMenu = false
    },
    handleMenuClick(data, menu, node) {
      switch (menu.id) {
        case 1:
          this.addChapter(data)
          break;
        case 2:
          this.renameChapter(data)
          break;
        case 3:
          this.hanldeStudySetting(data)
          break;
        case 4:
          this.exportChapter(data)
          break;
        case 5:
          this.copyChapter(data)
          break;
        case 6:
          this.deleteChapter(data, node)
          break;
      }
    },
    hanldeStudySetting(chapter) {
      let status = null
      let title = ''
      if (chapter.status === 1) {
        status = 2
        title = '是否要暂停学习该卡牌盒？'
      } else {
        status = 1
        title = '是否要恢复学习该卡牌盒？'
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        request.request({
          url: '/chapter/changeStatus',
          data: {
            chapterId: chapter.chapterId,
            status // status : 1是恢复学习，2是停止学习
          },
          method: 'post'
        }).then(res => {
          showMessage({message: '设置成功!', type: 'success'})
          this.$store.dispatch('modifyChapterList')
        }).catch(e => {
          showMessage({message: e.message, type: 'error'})
        })
      }).catch(e => {
      })
    },
    renameChapter(data) {
      this.$prompt('', '重命名卡牌盒', {
        confirmButtonText: '保存',
        cancelButtonText: '取消',
        inputValue: data.name,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm' && instance.inputValue && instance.inputValue !== data.name) {
            request.request({
              url: '/chapter/save',
              method: 'post',
              data: {
                chapterId: data.chapterId,
                name: instance.inputValue
              }
            }).then((res) => {
              data.name = instance.inputValue;
              showMessage({message: '修改成功！', type: 'success'})
              if (this.$store.state.toggleCatelog) {
                this.$store.dispatch('modifyAllChapterList')
              } else {
                this.$store.dispatch('modifyChapterList')
              }
              done();
            }).catch((e) => {
              showMessage({message: e, type: 'error'})
            })
          } else {
            done();
          }
        }
      });
    },
    addChapter(chapter) {
      console.log(chapter);
      this.$prompt('', '新建卡牌盒', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: '',
        inputPlaceholder: '请输入名称',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            const chapterId = new Date().getTime() + '001'
            request.request({
              url: '/chapter/save',
              method: 'post',
              data: {
                chapterId: chapterId,
                name: instance.inputValue,
                position: 0,
                parentId: chapter.chapterId
              }
            }).then((data) => {
              const newChild = {
                chapterId: chapterId,
                name: instance.inputValue,
                childList: [],
                level: chapter.level + 1,
                revCardNum: [0, 0],
                newCardNum: [0, 0],
                isShowMenu: false
              };
              if (!chapter.childList) {
                this.$set(chapter, 'childList', []);
              }
              chapter.childList.push(newChild);
              showMessage({message: '新增成功！', type: 'success'})
              done();
            }).catch((e) => {
              showMessage({message: e.message, type: 'error'})
            })
          } else {
            done();
          }
        }
      });
    },
    copyChapter(chapter) {
      handleChapterOperation(this, `拷贝 ${chapter.name} 吗?`, '拷贝卡牌盒',
        '/chapter/copy', {chapterId: chapter.chapterId}, '拷贝成功',
        () => {
          setTimeout(() => {
            location.reload()
          }, 500)
        })
    },
    exportChapter(chapter) {
      let loading = this.$loading({
        lock: true,
        text: '正在导出',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });
      request.request({
        url: '/chapter/exportExcel',
        params: {chapterId: chapter.chapterId}
      }).then(ret => {
        loading.close();
        location.assign("https://amazingmemo.com/api/index/chapter/getExportFile?file=" + ret.file);
      }).catch(e => {
        loading.close();
        showMessage({message: e.message, type: 'error'})
      });
    },
    deleteChapter(chapter, node) {
      handleChapterOperation(this, `确定删除 ${chapter.name} 吗?此操作将会删除该卡牌盒下的所有卡牌`, '删除卡牌盒',
        '/chapter/delete', {chapterId: chapter.chapterId}, '删除成功',
        () => {
          const parent = node.parent;
          const children = parent.data.childList || parent.data;
          const index = children.findIndex(d => d.chapterId == chapter.chapterId);
          children.splice(index, 1);
          this.$store.dispatch('modifyTotalChapterData')
          this.$store.dispatch('modifyAllChapterList')
          if (chapter.parentId === 0 && this.$route.name !== 'CardAll') {
            this.$router.push('/home/studyPlatform/cardAll')
            return
          }
          if (chapter.parentId !== 0) {
            this.$router.push('/home/studyPlatform/chapter/' + chapter.parentId)
          }
        })
    },
    // 子节点点击，变换右边盒子的数据
    nodeClick(data) {
      if (data.chapterId === 1001) {
        this.$router.push('/home/studyPlatform/cardAll')
      } else {
        if ((this.$route.name === 'Chapter' && this.$route.params.chapterId == data.chapterId) || data.chapterId === 999) {
        } else {
          this.$router.push('/home/studyPlatform/chapter/' + data.chapterId)
        }
      }
    },
    handleDragStart(node, ev) {
      if (node.data.chapterId === 1001) return false
    },
    //拖拽成功完成时触发的事件
    handleDrop(draggingNode, dropNode, dropType, ev) {
      console.log('tree drop: ', draggingNode.data, dropNode.data, dropType);
      request.request({
        url: '/chapter/move',
        method: 'post',
        data: {
          chapterId1: draggingNode.data.chapterId,
          chapterId2: dropNode.data.chapterId,
          type: dropType
        }
      }).then(res => {
        showMessage({message: '移动成功!', type: 'success'})
        // this.$store.dispatch('modifyChapterList')
      }).catch(e => {
        showMessage({message: e.message, type: 'error'})
        this.$store.dispatch('modifyChapterList')
        //移动失败后，tree组件内部会把移动的目录移动进去了，实际上是没有移动成功的，需要刷新一下书结构目录
      })
    },
  },
  computed: {
    expansionKeys() {
      return this.$route.params.chapterId ? [this.$route.params.chapterId * 1] : []
    },
    todayTotalCardNum() {
      return () => {
        if ((this.$store.state.totalChapterData.todayNewCardNum + this.$store.state.totalChapterData.todayRevCardNum) == 0) {
          return this.$store.state.totalChapterData.totalCardNum
        } else {
          return this.$store.state.totalChapterData.todayNewCardNum + this.$store.state.totalChapterData.todayRevCardNum
        }
      }
    },
    showNumColor() {
      return () => {
        if ((this.$store.state.totalChapterData.todayNewCardNum + this.$store.state.totalChapterData.todayRevCardNum) == 0) {
          return '#202020'
        } else {
          return '#ffb700'
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      if (this.$route.name === 'CardAll') {
        this.$refs.tree.setCurrentKey(1001)
      } else {
        this.$refs.tree.setCurrentKey(this.expansionKeys[0])
      }
    }, 500)
  }

}
</script>

<style lang="scss" scoped>
@import "@/assets/css/mixin.scss";

::v-deep .custom-tree-node {
  flex: 1;
  width: 0;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding-right: 16px;
  padding-left: 6px;

  .node-left {
    display: flex;
    align-items: center;
    overflow: hidden;
    padding-right: 18px;
    flex: 1;

    .folder {
      width: 16px;
      height: 16px;
      margin-right: 10px;
    }

    .node-name {
      flex: 1;
      font-size: 14px;
      color: #202020;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .node-right {
    display: flex;
    align-items: center;

    .node-num {
      color: #ffb700;
      font-size: 12px;
    }

    .node-num1 {
      color: #202020;
      opacity: .6;
      font-size: 12px;
    }

    .el-dropdown {
      display: flex;
    }
  }
}

::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
  padding: 0;
  margin-left: 12px;
}

::v-deep .el-tree-node__content {
  height: 44px !important;
}

::v-deep .el-tree-node__content {
  height: 36px;
  padding-top: 12px;
  padding-bottom: 11px;
}

::v-deep .el-input--prefix .el-input__inner {
  height: 32px;
  padding-left: 36px;
  font-weight: normal;
}

::v-deep .el-input__prefix {
  left: 12px;
  line-height: 36px;
}

::v-deep .el-tree-node:focus > .el-tree-node__content {
  background: #f7f7f7 !important;

}

::v-deep .el-tree-node__content:hover {
  background: rgba(123, 123, 128, 0.08) !important;
}

::v-deep .el-tree-node {
  margin-top: 1px;
}

::v-deep .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  height: 48px !important;
  width: 180px;
  padding-left: 16px;
  color: #202020;

  &:hover {
    background-color: #f7f7f7;
    color: #202020;
  }
}
</style>

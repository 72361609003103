<template>
  <div @click="hanldeToCardAll" class="chapter">
    <div class="chapter-container" :class="$route.name === 'CardAll' ? 'active' : ''">
      <!-- <img src="../../assets/images/card_all_default_cover.png" class="chapter-img"> -->
      <div class="chapter-content">
        <div class="chapter-title" :class="$route.name === 'CardAll' ? 'active' : ''">全部卡片</div>
        <div class="chapter-rank">
          学时 {{ formatTime($store.state.totalChapterData.totalStudyCardTime) }}
        </div>
        <div class="chapter-review">
          <div class="dx"
            :style="{ color: ($store.state.totalChapterData.todayNewCardNum + $store.state.totalChapterData.todayRevCardNum) === 0 ? 'rgba(32,32,32,.6)' : '#FFB700' }">
            今日待学 {{ $store.state.totalChapterData.todayNewCardNum + $store.state.totalChapterData.todayRevCardNum }}
          </div>
        </div>
        <div class="chapter-process">
          <el-progress :percentage="studyPercentage" :color="'#FFB700'" :stroke-width="6" :show-text="false">
          </el-progress>
          <div class="progress-text">已学{{ currentProgress() }}/{{ $store.state.totalChapterData.totalCardNum }}</div>
        </div>
        <!-- <div class="chapter-process">
          <el-progress :percentage="studyPercentage()" :color="'#FFB700'" :stroke-width="4" :show-text="false">
          </el-progress>
          <span>已掌握{{ currentProgress() }}/{{ $store.state.totalChapterData.totalCardNum }}</span>
        </div> -->
        <!-- <template v-else>
              <div class="chapter-review">
                <div class="dx" :style="{ color: chapterItem.revCardNum[0] === 0 ? 'rgba(32,32,32,.6)' : '#FF8800' }">
                  今日待复习 {{ chapterItem.revCardNum[0] }}
                </div>
              </div>
              <div class="chapter-process">
                <el-progress :percentage="hanldeCurrentProgress(chapterItem)"
                  :color="chapterItem.status === 1 ? '#FF8800' : 'rgba(32, 32, 32, 0.16)'" :stroke-width="4"
                  :show-text="false"></el-progress>
                <span>已掌握{{ chapterItem.handledCardNum }}/{{ chapterItem.cardNum }}</span>
              </div>
            </template> -->
      </div>
    </div>
  </div>
</template>
<script>
import { formatTime } from '../../libs/analyse';
export default {
  methods: {
    hanldeToCardAll() {
      if (this.$route.name !== 'CardAll') {
        this.$router.push('/home/studyPlatform/cardAll')
      }
    }
  },
  computed: {
    studyPercentage() {
      // return () => {
        if (this.$store.state.totalChapterData && this.$store.state.totalChapterData.totalNewCardNum === 0) {
          return Number(((this.$store.state.totalChapterData.handledCardNum / this.$store.state.totalChapterData.totalCardNum) * 100))
        } else {
          return Number((((this.$store.state.totalChapterData.totalCardNum - this.$store.state.totalChapterData.totalNewCardNum) / this.$store.state.totalChapterData.totalCardNum) * 100))
        }
      // }
    },
    currentProgress() {
      return () => {
        if (this.$store.state.totalChapterData.totalNewCardNum === 0) {
          return this.$store.state.totalChapterData.handledCardNum
        } else {
          return this.$store.state.totalChapterData.totalCardNum - this.$store.state.totalChapterData.totalNewCardNum
        }
      }
    },
    formatTime() {
      return function (time) {
        return formatTime(time)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.chapter {
  position: relative;
  border-radius: 6px;
  margin-bottom: 1px;
  padding: 0 10px;
  margin-top: 15px;

  &:hover {
    cursor: pointer;
  }

  .myicon {
    position: absolute;
    right: 8px;
    top: 10px;
    font-size: 16px;
  }

  .chapter-container {
    // height: 98px;
    padding: 10px 8px 10px 10px;
    display: flex;
    box-sizing: border-box;
    border-radius: 10px;
    border: 1px solid transparent;

    &.active {
      background: #f7f7f7;
      border-color: #ebebeb;
    }

    // .chapter-img {
    //   width: 58px;
    //   height: 100%;
    //   border-radius: 6px;
    //   object-fit: cover;
    // }

    .chapter-content {
      display: flex;
      flex-direction: column;
      flex: 1;

      .chapter-title {
        font-size: 15px;
        font-weight: 500;
        color: #202020;
        margin-right: 24px;
        word-break: break-all;
      }

      .chapter-rank {
        margin: 10px 0;
        color: rgba(32, 32, 32, 0.6);
        font-size: 12px;
        line-height: 12px;
      }

      .chapter-review {
        height: 22px;
        border-radius: 12px;
        padding: 5px 10px;
        width: max-content;
        background: rgba(255, 168, 0, .1);

        .dx {
          font-size: 12px;
          line-height: 12px;
        }
      }

      .chapter-process {
        margin-top: 6px;
        height: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        .el-progress {
          flex: 1;
          margin-right: 9px;
        }

        .progress-text {
          font-size: 12px;
          color: rgba(32, 32, 32, 0.6);
        }

        .finish {
          text-align: right;
          color: rgba(32, 32, 32, 0.6);
          line-height: 14px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>

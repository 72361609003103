
<template>
  <div class="catalog-container">
    <!-- 目录 -->
    <div class="catelog-header-nav">
      <div class="display-flex">
        <div class="catelog-header-nav-item" v-for="(menu, index) in navList" :key="menu.id"
          :class="currentIndex === index ? 'active' : ''" @click="onToggleClick(menu, index)">
          <svg-icon :iconClass="menu.name" :title="menu.title" position="bottom"></svg-icon>
        </div>
      </div>
      <div class="cursor-pointer position-relative" @click="handleAddOperation" @mouseenter="showAddMenu = true"
        @mouseleave="showAddMenu = false">
        <svg-icon iconClass="catelog_add"></svg-icon>
        <el-collapse-transition>
          <AddMenu v-show="showAddMenu" @handleMenuClick="handleMenuClick"></AddMenu>
        </el-collapse-transition>
      </div>
    </div>
    <div>
      <ChapterCardAll v-show="!showCatelogStatus"></ChapterCardAll>
      <CatelogTree v-show="showCatelogStatus" :chapterList="$store.state.enableChapterList"></CatelogTree>
      <CatelogTree v-show="showCatelogStatus" :chapterList="$store.state.disableChapterList"></CatelogTree>
      <ChapterList v-show="!showCatelogStatus" @openMakeChapter="openMakeChapter"
        @refreshChpaterHeaderData="refreshChpaterHeaderData" @moveChapter="moveChapter">
      </ChapterList>
    </div>
  </div>
</template>

<script>
import AddMenu from '../catelogMenu/AddMenu.vue';
import CatelogTree from '../catelogMenu/CatelogTree.vue';
import MakeCard from '../MakeCard.vue';
import ChapterList from '../home/ChapterList.vue';
import ChapterCardAll from '../catelogMenu/ChapterCardAll.vue';
export default {
  components: {
    AddMenu,
    CatelogTree,
    MakeCard,
    ChapterList,
    ChapterCardAll
  },
  data() {
    return {
      defaultProps: {
        children: 'childList',
        label: 'name'
      },
      currentIndex: 0,
      navList: [
        { name: 'mulu1', id: 1 ,title:'目录模式'},
        { name: 'mulu2', id: 2,title:'卡牌盒模式' },
      ],
      showAddMenu: false,
    }
  },
  methods: {
    moveChapter(chapter, index) {
      this.$emit('moveChapter', chapter, index)
    },
    refreshChpaterHeaderData(chapter) {
      this.$emit('refreshChpaterHeaderData', chapter)
    },
    openMakeChapter(chapter) {
      this.$emit('openMakeChapter', chapter)
    },
    handleMenuClick(menu) {
      this.showAddMenu = false
      this.$emit('handleMenuClick', menu)
    },
    handleAddOperation() {
      this.showAddMenu = true
    },
    hanldeToCardAll() {
      if (this.$route.name !== 'CardAll') {
        this.$router.push('/home/studyPlatform/cardAll')
      }
    },
    onToggleClick(menu, index) {
      if (this.currentIndex === index) return
      this.currentIndex = index
      this.$store.commit('setChangetToggleCatelog', !this.$store.state.toggleCatelog)
    },
  },
  computed: {
    showCatelogStatus() {
      return this.$store.state.toggleCatelog
    }
  },
  watch: {
    showCatelogStatus(n) {
      if (n) {
        this.currentIndex = 0
      } else {
        this.currentIndex = 1
      }
    }
  },
  created() {
    if (this.$store.state.toggleCatelog) {
      this.currentIndex = 0
    } else {
      this.currentIndex = 1
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/variable.scss";
@import "@/assets/css/mixin.scss";

.catalog-container {
  width: 100%;
  // position: fixed;
  // top: 0;
  // left: 54px;
  // width: 264px;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0px;
  }

  .catelog-header-nav {
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 6px 0;
    padding: 16px 16px 18px 18px;

    .catelog-header-nav-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 16px;
      width: 30px;
      height: 24px;
      border-radius: 6px;

      &:hover {
        cursor: pointer;
      }

      &.active {
        background: #f7f7f7;
      }
    }
  }

  .catelog-card-all {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 44px;
    color: #202020;
    padding: 0 16px 0 30px;

    &.active {
      font-weight: 500;
      color: #202020 !important;
    }

    &:hover {
      cursor: pointer;
    }

    .text {
      display: flex;
      font-size: 14px;
      align-items: center;
      color: #202020;

    }

    .num {
      font-size: 12px;
      opacity: .6;
    }
  }
}
</style>

<template>
  <div class="editor">
    <editor ref="myEditor" :model="model" @editorChange="editorChange" @showSaveBtn="showSaveBtn"
            @hideSaveBtn="hideSaveBtn">
    </editor>
    <div class="editor-footer">
      <div class="editor-model">
        <div @click="openSelectModel" class="editor-hover mg-r-32">
          <svg-icon iconClass="model" class="editor-model-img"></svg-icon>
          <span>{{ model !== null && Object.keys(model).length > 0 ? model.name : '选择模版' }}</span>
        </div>
        <div @click="openImportData" class="editor-hover">
          <svg-icon iconClass="more_import1" class="editor-model-img"></svg-icon>
          <span>批量制卡</span>
        </div>
      </div>
      <div class="h-34"></div>
      <div class="editor-btn" @click="saveEditorCard" v-if="isShowSaveBtn">
        <div class="text">{{ isIos ? 'Command+Enter' : 'Ctrl+Enter' }}</div>
        <svg-icon iconClass="save_edit_check" class="mg-l-24 w-20 h-20"></svg-icon>
      </div>
    </div>
    <!-- 选择模版 -->
    <select-model ref="selectModel"
                  @selectModel="selectModel"
                  @openImportAnki="openImportAnki"
                  :showSelectModel.sync="isShowSelectModel"
                  @updateShowSelectModel="updateShowSelectModel"
                  v-if="isShowSelectModel">
    </select-model>
  </div>
</template>
<script>
import request from '../../libs/api.request'
import SelectModel from "../SelectModel.vue"
import Editor from "../common/Editor.vue"
import {showMessage} from '../../libs/message'
import util from '@/libs/util';
import SvgIcon from '../common/SvgIcon.vue';
import {mapGetters} from 'vuex';
import {debounce} from '../../libs/analyse';

export default {
  components: {
    SelectModel,
    Editor,
    SvgIcon
  },
  data() {
    return {
      isIos: true,
      isShowSelectModel: false,
      isShowSaveBtn: false,
      model: {
        flds: [
          {
            "ord": 0,
            "name": "内容",
            'placeholder': "1.第一行结尾输入？生成记忆卡  2.输入单词，生成单词卡片 3.！！双叹号文字挖空！！"
          },
        ],
        name: "智能模板",
        modelId: 4,
        hideTitle: true
      },
      flds: [],
      isSaving: false
    }
  },
  methods: {
    openImportAnki() {
      this.$emit('openImportAnki')
    },
    openImportData() {
      this.$store.commit('setToggleFullScreen', false)
      this.$emit('openImportData')
    },
    showSaveBtn() {
      this.isShowSaveBtn = true
    },
    hideSaveBtn() {
      this.isShowSaveBtn = false
    },
    editorChange(tpl) {
      this.flds = tpl
    },
    // 保存卡牌
    saveEditorCard: debounce(function () {
      if (this.isSaving === true) return
      if (!this.model.modelId) {
        showMessage({message: '请选择模版', type: 'error'})
        return
      }
      let isEmpty = true;
      console.log(this.flds);
      for (let i = 0; i < this.flds.length; i++) {
        if (this.flds[i].value.trim().length > 0) {
          isEmpty = false;
          break;
        }
      }
      if (isEmpty) {
        showMessage({message: '你什么都没写~', type: 'warning'})
        return;
      }
      if (this.model.modelId == 5) {
        if (!this.isVip && this.vipLimitData && this.vipLimitData.wordCard.limit <= this.vipLimitData.wordCard.use) {
          this.$store.commit('changeShowIntroduceVip', true)
          return
        }
      }
      this.isSaving = true
      request.request({
        url: '/card/save',
        data: {
          cardId: new Date().getTime() + '001',
          chapterId: this.$route.params.chapterId || 100,
          modelId: this.model.modelId,
          flds: JSON.stringify(this.flds),
          flash: 1//标记首页闪记
        },
        method: 'post'
      }).then(res => {
        this.isSaving = false
        showMessage({message: '新增卡牌成功', type: 'success'})
        this.$refs.myEditor.tpl.forEach((e, k) => {
          this.$refs.myEditor.editors[k].setData('');
          if (k === 0) {
            this.$refs.myEditor.editors[0].focus()
          }
        });
        this.$refs.myEditor.initEditor()
        res['itemType'] = 2
        this.$emit('addCardSuccess', res)
        if (!this.isVip) {
          this.$store.dispatch('modifyUserInfo')
        }
      }).catch(e => {
        this.isSaving = false
        showMessage({message: e.message, type: 'error'})
      })
    }, 2000, true),
    // 选择的模版
    selectModel(model) {
      this.model = model
      this.$store.commit('setSelectedModel1', model)
      this.$emit('onModelChange', model)
    },
    openSelectModel() {
      this.isShowSelectModel = true
      // this.$refs.selectModel.open();
    },
    updateShowSelectModel(v) {
      this.isShowSelectModel = v
    },
    handleKeyDown(e) {
      if (sessionStorage.getItem('CardEditorComponentIsActive')) {
        return true;
      }

      console.log("HomeEditor handleKeyDown");
      // ctrol + enter 或者command
      if ((e.ctrlKey && e.keyCode === 13) || (e.metaKey && e.keyCode === 13)) {
        this.saveEditorCard()
        e.preventDefault();
        return
      }

      if ((e.keyCode === 116) || (e.metaKey && e.keyCode === 82)) {
        if ((e.keyCode === 116) || (e.metaKey && e.keyCode === 82)) {
          let isEmpty = true;
          for (let i = 0; i < this.flds.length; i++) {
            if (this.flds[i].value.trim().length > 0) {
              isEmpty = false;
              break;
            }
          }
          if (isEmpty) {
            return;
          }

          this.$confirm('编辑区的内容刷新后将会丢失，是否刷新页面？', '提示', {
              confirmButtonText: '是',
              cancelButtonText: '否',
              type: 'warning',
              beforeClose: (action, instance, done) => {
                done();
                if (action === 'confirm') {
                  location.reload()
                }
              }
            }
          );
          e.preventDefault();
          return
        }
      }
    }
  },
  computed: {
    ...mapGetters(['vipLimitData', 'isVip'])
  },
  created() {
    if (util.isIos()) {
      this.isIos = true
    } else {
      this.isIos = false
    }
    if (Object.keys(this.$store.state.selectedModel1).length > 0) {
      this.model = this.$store.state.selectedModel1
    }
  },
  mounted() {
    window.addEventListener('keydown', this.handleKeyDown, true)
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown, true);
  },
}
</script>
<style lang="scss" scoped>
.editor {
  width: 100%;
  border-radius: 12px;
  position: relative;
  padding-bottom: 16px;

  .editor-footer {
    width: 100%;
    margin-top: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    position: fixed;
    bottom: 8px;
    background-color: #f7f7f7;

    .editor-model {
      display: flex;

      .editor-hover {
        display: flex;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        .editor-model-img {
          width: 18px;
          height: 18px;
          margin-right: 8px;
        }

        span {
          font-size: 12px;
          color: #202020;
          line-height: 12px;
        }
      }
    }

    .editor-btn {
      display: flex;
      align-items: center;
      position: fixed;
      right: 24px;

      .text {
        font-size: 14px;
        color: #202020;
        opacity: .4;
      }

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>

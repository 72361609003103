<template>
  <div class="add-menu-box">
    <div class="add-menu-item" v-for="(menu,index) in menuList" :key="menu.id" @click="handleMenuClick(menu)">
      <svg-icon :iconClass="menu.icon" class="menu-icon"></svg-icon>
      <div class="menu-name">{{menu.name}}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      menuList:[
        {name:'新建卡牌盒',icon:'card_box',id:1},
        {name:'批量制卡',icon:'more_import',id:2},
        {name:'导入文件',icon:'import',id:3},
      ]
    }
  },
  methods: {
    handleMenuClick(menu) {
      this.$emit('handleMenuClick',menu)
    }
  },
}
</script>

<style lang="scss" scoped>
  .add-menu-box {
    position: absolute;
    right: -18px;
    top: 42px;
    background-color: #fff;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    z-index: 9; 
    .add-menu-item {
      width: 200px;
      height: 48px;
      padding: 0 16px;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        background-color: #f7f7f7;
      }
      .menu-name {
        font-size: 14px;
        color: #202020;
        line-height: 16px;
        margin-left: 14px;
      }
      .menu-icon {
        width: 16px;
        height: 16px;
      }
    }
  }
</style>
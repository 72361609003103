<template>
  <div class="studyPlatfrom">
    <SelectLayout v-if="showSelectLayout"></SelectLayout>
    <splitpanes style="height: 100vh" class="default-theme" @resize="hanldeSplitpaneRisize($event)">
      <pane :size="cateSize()"
        :style="{ minWidth: '264px', display: $store.state.showCatelog ? 'block' : 'none',overflow:'hidden' }"
        max-size="40">
        <div :class="$store.state.showCatelog ? 'nav' : 'nav1'" class="cate-log-box">
          <catalog @handleSonNodeClick="handleSonNodeClick" :class="$store.state.showCatelog ? 'nav' : 'nav1'"
            @handleMenuClick="handleMenuClick" @openMakeChapter="openMakeChapter"
            @refreshChpaterHeaderData="refreshChpaterHeaderData" @moveChapter="handleMoveChapter">
          </catalog>
        </div>
      </pane>
      <pane :size="mainSize()" style="overflow: hidden">
        <splitpanes class="default-theme" horizontal style="height: 100vh">
          <pane :size="chapterContentSize()" style="background-color: #f7f7f7;" class="customPane">
            <!-- <keep-alive> -->
            <router-view ref="myChapter" :showColumn="showColumn" :myChapterWidth="myChapterWidth"></router-view>
            <!-- </keep-alive> -->
          </pane>
          <pane :size="hideSize()" style="overflow:scroll;background-color: #f7f7f7;" min-size="5"
            :class="{ 'fullScreeStyle': $store.state.isFullScreen, 'my-pane': showTips && modelId === 4 }"
            class="mypane">
            <div class="editor-tip" v-show="showTips && modelId === 4">
              <div class="tip-text">{{ tipText[index] }}</div>
              <svg-icon :iconClass="iconclass" class="w-24 h-24 cursor-pointer" @click="onNextStep"></svg-icon>
            </div>
            <HomeEditor @addCardSuccess="addCardSuccess" @openImportData="openImportData" @onModelChange="onModelChange"
              @openImportAnki="openImportAnki"></HomeEditor>
          </pane>
        </splitpanes>
      </pane>
    </splitpanes>
    <make-card :title="title" :isFirstLevel="isFirstLevel" ref="makeChapter" @saveAddChapter="saveAddChapter"
      :currentChapter="chapter">
    </make-card>
    <import-data ref="importData"></import-data>
    <chapter-options ref="chapterOptions" @selectChapter="selectChapter">
    </chapter-options>
    <ImportAnki ref="importAnki"></ImportAnki>
  </div>
</template>

<script>
import request from '../libs/api.request'
import { setUserConfig } from '../libs/userConfig'
import Catalog from '../components/study/Catalog.vue'
import HomeEditor from '../components/home/HomeEditor.vue'
import MakeCard from '../components/MakeCard.vue'
import ImportData from '../components/ImportData.vue'
import SelectLayout from '../components/common/SelectLayout.vue'
import ChapterOptions from '../components/ChapterOptions.vue'
import { Splitpanes, Pane } from 'splitpanes'
import 'splitpanes/dist/splitpanes.css'
import { showMessage } from '../libs/message'
import ImportAnki from '../components/ImportAnki.vue'
export default {
  components: {
    Catalog,
    HomeEditor,
    MakeCard,
    ImportData,
    SelectLayout,
    Splitpanes,
    Pane,
    ChapterOptions,
    ImportAnki,
  },
  data() {
    return {
      iconclass: 'next_step_tick', // close_tip 
      tipText: ['第一行结尾输入？会识别成记忆卡', '输入生词，会自动生成单词卡', '！！挖空文字！！双叹号进行挖空', '多行内容会识别成单面笔记卡'],
      showTips: false,
      index: 0,
      showSelectLayout: '',
      chapter: {},
      title: '新建卡牌盒',
      isFirstLevel: true,
      myChapterWidth: 0,
      showColumn: 0,
      moveChapter: {}, // 被移动的卡牌
      modelId: 4,
      model: { flds: [] }
    }
  },
  methods: {
    openImportAnki() {
      this.$refs.importAnki.open()
    },
    onModelChange(model) {
      this.model = model
      this.modelId = model.modelId
    },
    onNextStep() {
      if (this.index < this.tipText.length - 1) {
        this.index++
      } else {
        this.showTips = false
        if (this.$store.state.userConfig.tipTextInfo !== undefined) {
          let num = JSON.parse(this.$store.state.userConfig.tipTextInfo).num + 1
          let option = {
            num,
            currentTime: new Date().getTime()
          }
          setUserConfig('tipTextInfo', JSON.stringify(option))
        } else {
          // 第一次设置
          console.log('diyici');
          setUserConfig('tipTextInfo', JSON.stringify({
            num: 1,
            currentTime: new Date().getTime()
          }))
        }

      }
      if (this.index === this.tipText.length - 1) {
        this.iconclass = 'close_tip'
      }
    },
    hanldeSplitpaneRisize(e) {
      this.handleResize()
      console.log(e);
    },
    openImportData() {
      this.$refs.importData.chapter = this.$refs.myChapter.$data.chapterHeaderData
      this.$refs.importData.open()
    },
    handleMoveChapter(chapter) {
      this.moveChapter = chapter
      this.$refs.chapterOptions.open()
    },
    selectChapter(chapter) {
      this.$confirm('将【' + this.moveChapter.name + '】移动至【' + chapter.name + '】内?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            request.request({
              url: '/chapter/putTopChapter',
              method: 'post',
              data: { chapterId: this.moveChapter.chapterId, moveTargetChapterId: chapter.chapterId }
            }).then(() => {
              done();
              showMessage({ message: '移动成功', type: 'success' });
              this.$store.dispatch('modifyChapterList')
              this.$store.dispatch('modifyAllChapterList')
              this.$refs.chapterOptions.close()
            }).catch((e) => {
              showMessage({ message: e.message, type: 'error' });
            })
          } else {
            done();
          }
        }
      });
    },
    refreshChpaterHeaderData(chapter) {
      if (this.$route.name === 'Chapter' && this.$route.params.chapterId == chapter.chapterId) {
        this.$refs.myChapter.getChapterInfo()
      }
    },
    // 添加卡片
    addCardSuccess(res) {
      // console.log(res);
      // this.$store.dispatch('changeTreeShowNum')
      if (this.$route.name === 'Chapter') {
        // 在当前目录结尾处添加一张卡牌
        // for (let i = 0; i < this.$refs.myChapter.$data.chapterDetailList.length; i++) {
        //   if (i !== 0) {
        //     if (this.$refs.myChapter.$data.chapterDetailList[i].itemType === 1) {
        //       this.$refs.myChapter.$data.chapterDetailList.splice(i, 0, res)
        //       hasChildCate = true
        //       break
        //     } else {
        //       console.log('没有子目录');
        //       hasChildCate = false
        //     }
        //   }
        // }
        // if (!hasChildCate) {
        //   // 没有子目录，加在当前目录的第一个
        //   this.$refs.myChapter.$data.chapterDetailList.splice(1, 0, res)
        // }
        // 卡片添加在最前面
        if (Array.isArray(res)) {
          res[0].itemType = 2
          res[1].itemType = 2
          this.$refs.myChapter.$data.chapterDetailList.splice(1, 0, res[1])
          this.$refs.myChapter.$data.chapterDetailList.splice(1, 0, res[0])
        } else {
          this.$refs.myChapter.$data.chapterDetailList.splice(1, 0, res)
        }
        // this.$refs.myChapter.$data.recentAddCardList.unshift(res)
        this.$refs.myChapter.getChapterInfo()
        this.$store.dispatch('modifyChapterList')
        this.$store.dispatch('modifyAllChapterList')
        this.$store.dispatch('modifyTotalChapterData')
      }
      if (this.$route.name === 'CardAll') {
        if (Array.isArray(res)) {
          res[0].itemType = 2
          res[1].itemType = 2
          this.$refs.myChapter.$data.cardList.unshift(res[1])
          this.$refs.myChapter.$data.cardList.unshift(res[0])
        } else {
          this.$refs.myChapter.$data.cardList.unshift(res)
        }
        this.$store.dispatch('modifyTotalChapterData')
      }
    },
    // 修改卡牌盒
    openMakeChapter(chapter) {
      console.log(chapter);
      this.chapter = chapter
      this.title = '修改卡牌盒'
      this.isFirstLevel = false
      this.$refs.makeChapter.open()
    },
    handleMenuClick(menu) {
      switch (menu.id) {
        case 1:
          this.chapter = {}
          this.title = '新建卡牌盒'
          this.isFirstLevel = true
          this.$refs.makeChapter.open()
          break;
        case 2:
          this.$refs.importData.open()
          break;
        case 3:
          this.$refs.importData.open()
          break;
      }
    },
    saveAddChapter() {
      this.$store.dispatch('modifyChapterList')
      this.$store.dispatch('modifyAllChapterList')
    },
    handleSonNodeClick(chapter) {
      this.$router.push('/home/studyPlatform/chapter/' + chapter.chapterId)
    },
    async getTreeChapterList() {
      this.$store.dispatch('modifyChapterList')
    },
    async getListChapterList() {
      this.$store.dispatch('modifyAllChapterList')
    },
    //获取模版列表
    getModelList() {
      request.request({
        url: '/model/all'
      }).then(res => {
        this.$store.commit('setModelList', res.list)
      })
    },
    // 54px侧边栏，48px左右的padding，264px是树形侧边栏 
    handleResize() {
      let clientWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      if (this.$store.state.showCatelog) {
        this.myChapterWidth = clientWidth - 54 - 48 - 264 - 24 // windows滚动条会占大小
      } else {
        this.myChapterWidth = clientWidth - 54 - 48 - 24
      }
      // console.log(this.myChapterWidth / 260);
      // (this.myChapterWidth/ 260).toFixed()
      this.showColumn = Math.round(this.myChapterWidth / 260)
      // console.log(this.myChapterWidth, this.showColumn);
    },
    getUserConfig() {
      request.request({
        url: '/user/getUserConfig'
      }).then(res => {
        if (Object.keys(res.conf).length === 0) {
          this.showSelectLayout = true
          this.showTips = true
          console.log('没有配置');
        } else {
          console.log('有配置');
          this.$store.commit('setUserConfig', res.conf)
          if (res.conf.showSelectLayout) {
            this.showSelectLayout = res.conf.showSelectLayout === 'false' ? false : true
          }
          if (res.conf.tipTextInfo) {
            // let day = Math.floor((new Date().getTime() - JSON.parse(res.conf.tipTextInfo).currentTime) / 1000 / 60 / 60 / 24) // 天数
            // console.log(day);
            // if (day >= 5) {
            //   this.showTips = true
            // } else {
            //   this.showTips = false
            // }
            if (JSON.parse(res.conf.tipTextInfo).num >= 1) {
              this.showTips = false
            } else {
              this.showTips = true
            }
          } else {
            this.showTips = true
          }
        }
      })
    }
  },
  computed: {
    chapterContentSize() {
      return () => {
        if (this.$store.state.isSelectAll) {
          return 95
        } else {
          return 80
        }
      }
    },
    hideSize() {
      return () => {
        if (this.$store.state.isSelectAll) {
          return 0
        } else {
          if (this.model.flds.length >= 4) {
            return 100
          } else {
            return ((this.model.flds.length) * 10) + 20
          }
        }
      }
    },
    mainSize() {
      return () => {
        if (!this.$store.state.showCatelog) {
          return 100
        }
      }
    },
    cateSize() {
      return () => {
        if (this.$store.state.showCatelog) {
          return 10
        } else {
          return 0
        }
      }
    }
  },
  watch: {
    '$store.state.showCatelog': {
      deep: true,
      handler(n) {
        this.handleResize()
      }
    },
    $route() {
      if (this.$store.state.isSelectAll) {
        this.$store.commit('setSelectAll', false)
      }
    }
  },
  created() {
    this.getTreeChapterList()
    this.getListChapterList()
    this.getModelList()
    this.$store.dispatch('modifyTotalChapterData')
    this.getUserConfig()
  },
  mounted() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize, true)
  },
}
</script>

<style lang="scss" scoped>
.home-wx {
  position: fixed;
  bottom: 180px;
  right: 24px;
  background: #fff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}

.my-pane {
  position: relative;
  overflow: inherit !important;

  .editor-tip {
    position: absolute;
    top: -53px;
    left: 0;
    right: 0;
    height: 44px;
    width: 100%;
    background: #F8EBD2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 99;
    padding: 0 24px 0 16px;

    .tip-text {
      color: #FF9400;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
    }
  }
}

.home-edit {
  position: relative;
}

.studyPlatfrom {
  display: flex;
  overflow: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  #content-box {
    overflow-y: auto;
  }

  .cate-log-box {
    height: 100%;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  // .showCatelog {
  //   width: 264px !important;
  //   transition: width .1s;
  // }
  // .hideCatelog {
  //   width: 0 !important;
  //   transition: width .1s;
  // }
  .nav {
    // width: 264px;
    transition: width .1s;
  }

  .nav1 {
    width: 0;
    transition: width .1s;
  }

  .studyPlatfrom-content {
    flex: 1;
    position: relative;

    .top-menu {
      display: flex;
      justify-content: space-between;

      .right-menu {
        display: flex;
        align-items: center;

        .text {
          margin-right: 20px;

          span {
            color: #ffb700;
          }
        }
      }
    }

    .chapter {
      flex: 1;
      padding: 0 24px 0 12px;
    }
  }
}

.fullScreeStyle {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh !important;
  z-index: 999;
  transition: all .2s;
  padding: 0px 8px 0 12px;
}

::v-deep .default-theme.splitpanes--vertical>.splitpanes__splitter {
  margin-left: -7px !important;
  border-left: none !important;
  background: none;
}

::v-deep .splitpanes--horizontal>.splitpanes__splitter {
  height: 10px !important;
  background: #f7f7f7 !important;
  border-top: 1px solid #eee !important;
}

::v-deep .splitpanes__splitter {
  margin-top: 0 !important;
  z-index: 99;
  // background: transparent !important;
}

::v-deep .splitpanes__splitter::after {
  width: 0 !important;
}

::v-deep .splitpanes__splitter:before {
  width: 0 !important;
}

::v-deep .splitpanes__pane {
  overflow-y: scroll;
  // scrollbar-width: none;

  // &::-webkit-scrollbar {
  //   width: 0;
  // }

}

::v-deep .mypane {
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>

<template>
  <div class="layout-box">
    <div class="model"></div>
    <div class="layout-content">
      <div class="layout-head">
        <div class="select" style="opacity: 0;"></div>
        <div class="title">选择首页布局模式</div>
        <div class="select" @click="onClose"><i class="el-icon-check"></i></div>
      </div>
      <div class="layout-center">
        <div class="layout-item" v-for="(item, index) in layoutList" :key="index" @click="handleLayoutSeelct(item,index)">
          <img :src="item.image" class="layout-img" :class="currentIndex===index?'active':''">
          <div class="layout-title">{{ item.title }}</div>
          <div class="layout-desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import request from '../../libs/api.request'
export default {
  data() {
    return {
      currentIndex:0,
      layoutList: [
        {
          image: require('../../assets/images/layout2.png'),
          title: '目录模式',
          desc: '快速记录单词、知识点、灵感'
        },
        {
          image: require('../../assets/images/layout1.png'),
          title: '卡片模式',
          desc: '数据可视化、复习记忆为主'
        }
      ]
    }
  },
  methods: {
    onClose() {
      this.$parent.showSelectLayout = false
      request.request({
        url:'/user/setUserConfig',
        method:'post',
        data: {
          key: 'showSelectLayout',
          value: JSON.stringify(false)
        }
      })
      // localStorage.setItem('showSelectLayout',JSON.stringify(false))
    },
    handleLayoutSeelct(item,index) {
      if(this.currentIndex=== index) return
      this.currentIndex = index
      this.$store.commit('setChangetToggleCatelog',!this.$store.state.toggleCatelog)
    }
  },
  computed: {
    showCatelogStatus(){
      return this.$store.state.toggleCatelog
    }
  },
  watch: {
    showCatelogStatus(n) {
      if(n) {
        this.currentIndex = 0
      }else {
        this.currentIndex = 1
      }
    }
  },
  created() {
    if(this.$store.state.toggleCatelog) {
      this.currentIndex = 0
    }else {
      this.currentIndex = 1
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-box {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  .model {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #202020;
    opacity: 0.4;
  }

  .layout-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    width: 744px;
    height: 520px;
    box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background: linear-gradient(360deg, #F7F7F7 0%, #FFF9EB 100%);

    .layout-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 44px;

      .title {
        font-size: 18px;
        font-weight: 500;
        color: #00000A;
      }

      .select {
        width: 64px;
        height: 34px;
        background: #FFA800;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          cursor: pointer;
        }

        i {
          font-size: 20px;
          color: #fff;
        }
      }
    }

    .layout-center {
      display: flex;
      justify-content: center;

      .layout-item {
         text-align: center;
         &:hover {
           cursor: pointer;
         }
         &:nth-child(1) {
            margin-right: 30px;
          }
        .layout-img {
          width: 220px;
          height: 294px;
          border-radius: 10px;
          &.active {
            border: 3px solid #FFA800;
          }
        }

        .layout-title {
          font-size: 16px;
          font-weight: 500;
          color: #202020;
          line-height: 22px;
          margin-top: 20px;
        }
        .layout-desc {
          font-size: 12px;
          color: #202020;
          line-height: 14px;
          margin-top: 5px;
          opacity: .6;
          transform: scale(0.9);
        }
      }
    }
  }
}
</style>
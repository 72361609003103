import request from './api.request'

export const setUserConfig = (key,value) => {
  request.request({
    url: '/user/setUserConfig',
    method: 'post',
    data: {
      key,
      value
    }
  })
}

export const clearUserConfig = (key) => {
  request.request({
    url: '/user/removeUserConfig',
    method: 'post',
    data: {
      key
    }
  })
}
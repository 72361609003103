<template>
  <el-row class="chapter-box not-select" style="margin: 0">
    <draggable
        :list="$store.state.allChapterList"
        forceFallback="true"
        animation="1000"
        @start="onStart"
        @end="onEnd"
        tag="div"
        filter=".forbid"
        fallbackTolerance="100"
        style="width: 100%;"
    >
      <el-col
          :span="24"
          class="chapter"
          v-for="(chapterItem, index) in $store.state.allChapterList"
          :key="chapterItem.chapterId"
          @mouseleave.native="handleMouseLeave(index)"
      >
        <div @click="chapterClick(chapterItem,index)" @mouseenter="handleMouseEnter(index)">
          <el-dropdown :show-timeout="0" class="myicon" v-show="currentIndex === index" placement="bottom-start">
            <div class="el-icon-more" @click.stop></div>
            <el-dropdown-menu slot="dropdown" style="border-radius: 8px;">
              <el-dropdown-item @click.native.stop="onMenuClick(menu,chapterItem,index)" v-for="menu in menuList" :key="menu.id">
              <svg-icon :iconClass="menu.icon" class="w-16 h-16"></svg-icon>
              <div class="fs-14 mg-l-14" v-if="menu.id === 3">{{ chapterItem.status === 1 ? '停止学习' : '恢复学习' }}</div>
              <div class="fs-14 mg-l-14" v-else>{{ menu.menuName }}</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="chapter-container" :class="chapterItem.chapterId == $route.params.chapterId?'active':''">
            <div class="chapter-content">
              <div class="chapter-title" :class="currentChapterIndex===index?'active':''">{{ chapterItem.name }}</div>
              <div class="chapter-rank">
                学时 {{ formatTime(chapterItem.studyTime) }}
              </div>
              <template v-if="chapterItem.cardNum === 0 || chapterItem.newCardNum[1] > 0">
                <div class="chapter-review" v-if="chapterItem.status !== 2">
                  <div class="dx"
                      :style="{color:(chapterItem.revCardNum[0]+ chapterItem.newCardNum[0])===0 ?'rgba(32,32,32,.6)': '#FFB700'}">
                  今日待学 {{ chapterItem.newCardNum[0] + chapterItem.revCardNum[0] }}
                  </div>
                </div>
                <div class="chapter-process">
                  <el-progress
                      :percentage="currentProgress(chapterItem)"
                      :color="chapterItem.status === 1 ? '#FFB700' : 'rgba(32, 32, 32, 0.16)'"
                      :stroke-width="6"
                      :show-text="false"
                  ></el-progress>
                  <div class="progress-text">已学{{ chapterItem.cardNum - chapterItem.newCardNum[1] }}/{{ chapterItem.cardNum }}</div>
                </div>
              </template>
              <template v-else>
                <div class="chapter-review" v-if="chapterItem.status !== 2">
                  <div class="dx" :style="{color:chapterItem.revCardNum[0] ===0 ?'rgba(32,32,32,.6)':'#FF8800'}">
                    今日待复习 {{ chapterItem.revCardNum[0] }}
                  </div>
                </div>
                <div class="chapter-process">
                  <el-progress
                      :percentage="hanldeCurrentProgress(chapterItem)"
                      :color="chapterItem.status === 1 ? '#FF8800' : 'rgba(32, 32, 32, 0.16)'"
                      :stroke-width="6"
                      :show-text="false"
                  ></el-progress>
                  <div class="progress-text">已掌握{{ chapterItem.handledCardNum }}/{{ chapterItem.cardNum }}</div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </el-col>
    </draggable>
  </el-row>
</template>
<script>
import request from '../../libs/api.request'
import {formatTime} from '../../libs/analyse';
import draggable from 'vuedraggable'
import {showMessage} from '../../libs/message';

export default {
  props: {
    chapterStatus: {
      type: Number,
      default: 1
    }
  },
  components: {
    draggable
  },
  data() {
    return {
      currentIndex: '',
      currentChapterIndex:'',
      menuList:[
        // { menuName: '创建卡牌盒', icon: 'add_folder', id: 1 },
        { menuName: '修改卡牌盒', icon: 'edit', id: 2 },
        { menuName: '停止学习', icon: 'stop_study', id: 3 },
        { menuName: '导出卡牌盒', icon: 'export_chapter', id: 4 },
        { menuName: '移动卡牌盒', icon: 'move_chapter', id: 5 },
        { menuName: '拷贝卡牌盒', icon: 'copy_chapter', id: 6},
        { menuName: '删除卡牌盒', icon: 'delete_box', id: 7 },
      ]
    }
  },
  methods: {
    onMenuClick(menu,chapter,index) {
      switch (menu.id) {
        case 2:
          // 修改卡牌盒
          this.renameChapterBox(chapter)
        break;
        case 3:
          // 停止恢复学习
          this.stopChapterBox(chapter)
        break;
        case 4:
          // 导出
          this.exportChapter(chapter)
        break;
        case 5:
          // 移动卡牌盒
          this.moveChapterBox(chapter,index)
        break;
        case 6:
          // 拷贝卡牌盒
          this.codyChapterBox(chapter,index)
        break;
        case 7:
          //删除卡牌盒
          this.deleteChapterBox(chapter,index)
        break;
      }
    },
    handleMouseLeave() {
      this.currentIndex = ''
    },
    handleMouseEnter(index) {
      this.currentIndex = index
    },
     exportChapter(chapter) {
      let loading = this.$loading({
        lock: true,
        text: '正在导出',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)'
      });
      request.request({
        url: '/chapter/exportExcel',
        params: { chapterId: chapter.chapterId }
      }).then(ret => {
        loading.close();
        location.assign("https://amazingmemo.com/api/index/chapter/getExportFile?file=" + ret.file);
      }).catch(e => {
        loading.close();
        showMessage({ message: e.message, type: 'error' })
      });
    },
    moveChapterBox(chapter, index) {
      this.$emit('moveChapter',chapter, index)
    },
    codyChapterBox(chapter) {
      this.$confirm(`拷贝 ${chapter.name} 吗?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let loading = this.$loading({
              lock: true,
              text: '正在拷贝卡牌盒...',
              spinner: 'el-icon-loading',
              background: 'rgba(0, 0, 0, 0.5)'
            });
            done();
            request.request({
              url: '/chapter/copy',
              method: 'post',
              data: { chapterId: chapter.chapterId, }
            }).then((data) => {
              loading.close()
              this.$store.dispatch('modifyChapterList')
              this.$store.dispatch('modifyAllChapterList')
              this.$store.dispatch('modifyTotalChapterData')
              showMessage({ message: '拷贝成功!', type: 'success' })
            }).catch((e) => {
              showMessage({ message: e.message, type: 'error' })
              loading.close()
            })
          } else {
            done();
          }
        }
      })
    },
    renameChapterBox(chapter) {
      this.$emit('openMakeChapter', chapter)
    },
    // 暂停学习卡牌盒
    stopChapterBox(chapter) {
      let status = null
      let title = ''
      if (chapter.status === 1) {
        status = 2
        title = '是否要暂停学习该卡牌盒？'
      } else {
        status = 1
        title = '是否要恢复学习该卡牌盒？'
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(() => {
        request.request({
          url: '/chapter/changeStatus',
          data: {
            chapterId: chapter.chapterId,
            status // status : 1是恢复学习，2是停止学习
          },
          method: 'post'
        }).then(res => {
          chapter.status = status;
          showMessage({message: '设置成功！', type: 'success'})
          this.$store.dispatch('modifyChapterList')
          this.$emit('refreshChpaterHeaderData',chapter)
        }).catch(err => {
          showMessage({message: err.message, type: 'error'})
        })
      }).catch(e => {
      })
    },
    // 删除卡牌盒
    deleteChapterBox(chapter, index) {
      this.$confirm(`确定删除 ${chapter.name} 吗?`, '删除卡牌盒', {
        confirmButtonText: '删除',
        cancelButtonText: '取消',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            request.request({
              url: '/chapter/delete',
              method: 'post',
              data: {chapterId: chapter.chapterId,}
            }).then((data) => {
              showMessage({message: '删除成功！', type: 'success'})
              this.$store.commit('deleteChapter',index)
              this.$store.dispatch('modifyChapterList')
              this.$store.dispatch('modifyTotalChapterData')
              if(this.$route.name === 'Chapter'&& this.$route.params.chapterId == chapter.chapterId) {
                this.$router.push('/home/studyPlatform/cardAll')
              }
              done();
            }).catch((e) => {
              showMessage({message: e.message, type: 'error'})
            })
          } else {
            done();
          }
        }
      })
    },
    chapterClick(chapter,index) {
      if(chapter.chapterId == this.$route.params.chapterId) return
      this.currentChapterIndex = index
      this.$router.push('/home/studyPlatform/chapter/'+ chapter.chapterId)
    },
    createCradBox() {
      this.$emit('openMakeCard')
    },
    onStart(e) {
      // console.log('开始拖拽',e);
    },
    onEnd(e) {
      // console.log('拖拽完成',e.oldIndex,e.newIndex);
      // console.log(e.item._underlying_vm_);
      if (e.newIndex === e.oldIndex) return
      const chapter = e.item._underlying_vm_
      request.request({
        url: '/chapter/move',
        method: 'post',
        data: {
          chapterId1: chapter.chapterId,
          type: 'top',
          position: e.newIndex + 1
        }
      }).then(res => {
        showMessage({message: '移动成功！', type: 'success'})
      }).catch(e => {
        showMessage({message: e, type: 'error'})
      })
    }
  },
  computed: {
    // 学习状态下的，进度
    currentProgress() {
      return function (chapterItem) {
        return chapterItem.cardNum > 0 ? ((chapterItem.cardNum - chapterItem.newCardNum[1]) / chapterItem.cardNum).toFixed(2) * 100 : 0
      }
    },
    hanldeCurrentProgress() {
      return function (chapterItem) {
        return chapterItem.cardNum > 0 ? (chapterItem.handledCardNum / chapterItem.cardNum).toFixed(2) * 100 : 0
      }
    },
    formatTime() {
      return function (time) {
        return formatTime(time)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/mixin.scss";

.chapter-box {
  display: flex;
  flex-wrap: wrap;
  padding: 0 8px 0 6px;

  .chapter {
    position: relative;
    margin-bottom: 1px;
    &:hover {
      cursor: pointer;
    }
    .myicon {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 16px;
    }

    .chapter-container {
      height: 126px;
      padding: 16px 10px 10px;
      display: flex;
      box-sizing: border-box;
      border-radius: 10px;
      border: 1px solid transparent;
      &.active {
        border-color: #e6e6e6;
        background: #f7f7f7;
      }

      .my-placeholder {
        visibility: hidden;
      }
      .chapter-content {
        display: flex;
        flex-direction: column;
        flex: 1;

        .chapter-title {
          font-size: 15px;
          font-weight: 500;
          color: #202020;
          margin-right: 24px;
          @include clamp(1);
          word-break: break-all;
        }

        .chapter-rank {
          margin: 10px 0;
          color: rgba(32, 32, 32, 0.6);
          font-size: 12px;
          line-height: 12px;
        }

        .chapter-review {
          height: 22px;
          border-radius: 12px;
          padding: 5px 10px;
          width: max-content;
          background: rgba(255, 168, 0, .1);
          .dx {
            font-size: 12px;
            line-height: 12px;
          }
        }

        .chapter-process {
          margin-top: 6px;
          height: 18px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-grow: 1;

          .progress-text {
            font-size: 12px;
            color: rgba(32, 32, 32, 0.6);
          }
          .el-progress {
            flex: 1;
            margin-right: 9px;
          }
          .finish {
            text-align: right;
            color: rgba(32, 32, 32, 0.6);
            line-height: 14px;
            font-size: 12px;
          }
        }
      }
    }
  }
}

::v-deep .el-dropdown-menu {
  padding-top: 0;
  padding-bottom: 0;
}

::v-deep .el-dropdown-menu__item {
  display: flex;
  align-items: center;
  height: 48px !important;
  width: 180px;
  padding-left: 16px;
  color: #202020;
  &:hover {
    background-color: #f7f7f7;
    color: #202020;
  }
}
</style>
<style>
 .el-progress-bar__outer {
    background-color: rgba(255, 168, 0, .1);
}
</style>